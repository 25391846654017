
import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

function ClosedLiveExamListContent() {


    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '15px',
        fontWeight: 700
    };

    const formgrouptitle={
        margin: '15px',
        fontWeight: 700,
        fontSize: 'large'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    // For Modal
    let [liveexamname,setLiveexamname]=useState("");
    let [liveexamid,setLiveexamid]=useState("");
    let [liveexamschedule,setLiveexamschedule]=useState("NEW EXAM");
    let [livestartschedule,setLivestartschedule]=useState("");
    let [liveendschedule,setLiveendschedule]=useState("");

    // let [livestartendschedule,setLivestartendschedule]=useState("");

    let [qidcount,setQidcount]=useState("");
    let [modal,setModal]=useState("");
    let [currentshuffle,setCurrentshuffle]=useState("");
    let [newshuffle,setNewshuffle]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    let [ismodalbtn,setIsmodalbtn]=useState(true);

    let [livesetserialid,setLivesetserialid]=useState("");
    let [isserialid,setIsserialid]=useState(false);
    let [livesetmode,setLivesetmode]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [isnewbtnmodal,setIsnewbtnmodal]=useState(true);
    // For Modal

    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconpending={
        color: '#900000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    let [livesetclosedweeklyarr, setLivesetclosedweeklyarr]=useState([]);
    let [livesetcloseddailyarr, setLivesetcloseddailyarr]=useState([]);

    let [loader,setLoader]=useState(true);
    let [loader2,setLoader2]=useState(true);

    let [countclosedexamdaily, setCountclosedexamdaily]=useState(0);
    let [countclosedexamweekly, setCountclosedexamweekly]=useState(0);

    async function getLivesetcount() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getlivesetcount");
        var data=resp.data;
        setCountclosedexamdaily(data.dataclosedexamdailycount);
        setCountclosedexamweekly(data.dataclosedexamweeklycount);
    }

    async function getClosedweeklyexamlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getclosedweeklyexamlist");
        var data=resp.data;
        setLivesetclosedweeklyarr(data);
        setLoader(false);
    }
    async function getCloseddailyexamlist() {
        var resp=await axios.get("https://safalata.in/safalatalara/api/getcloseddailyexamlist");
        var data=resp.data;
        setLivesetcloseddailyarr(data);
        setLoader2(false);
    }      

    let [livestartendschedule,setLivestartendschedule]=useState("");
    

    useEffect(()=>{
        getLivesetcount();
        getClosedweeklyexamlist();
        getCloseddailyexamlist();
    },[])



    return(
        <>
        <div className="container-fluid px-4">
            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CLOSED EXAM LIST (WEEKLY - SATURDAY AND SUNDAY) - TOTAL CLOSED EXAM ( {countclosedexamweekly} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    {/* <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Group ID</th> */}
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Live ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Total Question</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%'}}>Next Schedule Date</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetclosedweeklyarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                {/* <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveexamset_serial_no}</td> */}
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.countqid}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%', color: '#01691d'}}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MMTT")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MMTT")}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>
                                    EXAM CLOSED
                                </td>
                                <td className='text-center' style={{width: '15%'}}>
                                <button className="btn btn-primary" disabled><i className="fas fa-check-double" style={iconpending}></i> Selection</button>
                                </td>
                            </tr>

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    CLOSED EXAM LIST (DAILY) - TOTAL CLOSED EXAM ( {countclosedexamdaily} )
                </div>
                <div className="card-body">
                    
                {!loader2?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Group ID</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>Live ID</th>
                                    <th style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>Live Set Name</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '7%'}}>Total</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%'}}>Next Schedule Date</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%'}}>End Duration</th>
                                    <th className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '18%'}}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {livesetcloseddailyarr.map((e)=>
                                
                            <tr key={e.liveset_slno}>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveexamset_serial_no}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '10%'}}>{e.liveset_slno}</td>
                                <td style={{fontWeight: 700, fontSize: 'medium', width: '20%'}}>{e.liveset_name}</td>
                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '7%'}}>{e.countqid}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '30%', color: '#01691d'}}>{dateFormat(e.liveexamsetselection_start_schedule, "dd-mm-yyyy HH:MMTT")+'-'+dateFormat(e.liveexamsetselection_end_schedule, "HH:MMTT")}</td>

                                <td className='text-center' style={{fontWeight: 700, fontSize: 'medium', width: '15%', color: '#c70000'}}>
                                    EXAM CLOSED
                                </td>
                                <td className='text-center' style={{width: '18%'}}>
                                <button className="btn btn-primary small" onClick={()=>{
                                    openModal();
                                    setLiveexamid(e.liveset_slno);
                                    setLiveexamname(e.liveset_name);
                                }}><i className="fas fa-check-double" style={iconpending}></i> Re-Schedule</button>
                                </td>
                            </tr>

                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>


        </div>
        

        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM SELECTION:::
            </div>
            <div className="form-group" style={formgroup}>
                LIVE SET ID : {liveexamid}
            </div>
            <div className="form-group" style={formgroup}>
                LIVE SET NAME : {liveexamname}
            </div>
            <div className="form-group" style={formgroup}>
                <label>START &amp; END SCHEDULE :</label>
                <input type="date" className='form-control' onChange={(ev)=>{
                    setLivestartendschedule(ev.target.value);
                    if (livesetserialid.length>0 && ev.target.value.length>0) {
                        setIsmodalbtn(false);
                    } else {
                        setIsmodalbtn(true);
                    }
                }}/>
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setLivesetserialid(ev.target.value);
                    if (ev.target.value.length>0 && livestartendschedule.length>0) {
                        setIsserialid(false);
                        setIsnewbtnmodal(false);
                    } else {
                        setIsnewbtnmodal(true);
                    }
                }} placeholder="SERIAL NUMBER FOR THAT DAY [e.g. 9]" value={livesetserialid} disabled={isserialid}/>
                {/* {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''} */}
            </div>
            
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                // alert(liveexamid+', '+livesetserialid+', '+livesetmode);
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("livesetid",liveexamid);
                fd.append("livesetserialid",livesetserialid);
                fd.append("livestartendschedule",livestartendschedule);
                // alert(liveexamid+', '+livesetserialid+', '+livesetmode);
                var resp=await axios.post("https://safalata.in/safalatalara/api/updateliveexamsetselectionnew",fd);
                var data=resp.data;
                
                setIsnewbtnmodal(true);
                setTimeout(()=>{
                    setIsOpen(false);
                    getLivesetcount()
                    getCloseddailyexamlist()
                    setLivesetserialid("");
                    setLivesetmode("");
                }, 100)
                       
            }} className="btn btn-primary" disabled={isnewbtnmodal}>Save Changes</button>
            </div>
        </Modal>

        {/* FOr Modal */}


        </>
    );

}

export default ClosedLiveExamListContent;